<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:title>
      <v-row class="ma-1">
        <v-btn-toggle v-model="objectType" mandatory borderless dense @change="onChangeObjectType" class="ml-1">
          <v-btn value="objects" class="mx-1">
            <span class="hidden-md-and-down">Объекты</span>
            <v-icon :right="$vuetify.breakpoint.mdAndUp"> far fa-building </v-icon>
          </v-btn>
          <v-btn value="lampObjects" class="mr-1">
            <span class="hidden-md-and-down">Фонарики</span>
            <v-icon :right="$vuetify.breakpoint.mdAndUp">fas fa-lightbulb</v-icon>
          </v-btn>
          <v-btn value="agentObjects">
            <span class="hidden-md-and-down">Агенты</span>
            <v-icon :right="$vuetify.breakpoint.mdAndUp"> fas fa-user-secret </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()">
        <btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-if="showEditDialog" v-model="showEditDialog" :id="idEdit" :api="types[objectType].url" :objectType="objectType" @close="itemShowClose($event)" />
      <s-editor-edit-field v-if="showEditField" v-model="showEditField" :values="editField.data" :model="editField.model" @commit="onCommitField($event)" />
    </portal>
    <a-table-f-api
      v-if="true"
      ref="table"
      :api="types[objectType].url"
      :model="model"
      :useQuery="false"
      :defaults="defaults"
      :selectedRows="selectedRows"
      @onEditField="onEditField($event)"
      @click="
        counter += 1;
        onDetectClick($event);
      "
    />
  </static-fullscreen-card>
</template>

<script>
import { getAccess, keyDetect, doubleClickDetect, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, keyDetect, doubleClickDetect, genModel],
  components: {
    editDialog: () => import("./dialogs/objectSiteDialog"),
  },
  data() {
    return {
      idEdit: 0,
      objectType: "objects",
      editField: null,
      showEditDialog: false,
      showEditField: false,
      types: { objects: { url: "/mechti/objects/published" }, lampObjects: { url: "/mechti/lamp_objects" }, agentObjects: { url: "/mechti/agent_objects" } },
      title: "",
      m: this.$store.getters["config/get"].models.site,
      url: "/mechti/objects/published",
      urlEdit: "/mechti/objects/published",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: {},
        paramName: "site",
      },
      data: null,
    };
  },
  created() {
    this.$root.title = "На сайте";
    this.fitchData();
  },
  computed: {
    modelT() {
      console.log("get model T");
      let model = this.getModelList(this.m, "list", false);
      return model;
    },
    model() {
      let res = this.modelT;
      let fields = this.m[this.objectType].split(",");
      let opt = [];
      if (this.objectType == "objects") opt = this.getOptions({ options: "status_object" });
      if (this.objectType == "lampObjects") opt = this.getOptions({ options: "config.statuses.lamp_object" }).filter((s) => s.type == "lampObjects");
      if (this.objectType == "agentObjects") opt = this.getOptions({ options: "config.statuses.lamp_object" }).filter((s) => s.type == "agentObjects");
      res.find((m) => m.name == "status").options = [...opt];
      res.find((m) => m.name == "ap_price_buy").editable = !(this.objectType == "objects");
      return res.filter((f) => fields.includes(f.name));
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.itemShowClose();
      }
    },
  },
  methods: {
    async post(data, api, silent = false) {
      this.loading = true;
      try {
        let response = await this.$axios.post(api, data);
        this.loading = false;
        let status = response.data.status == "ok";
        if (response.data.data) {
          Object.assign(this.data, response.data.data);
          if (!silent)
            this.$root.$emit("show-info", {
              text: "Данные записаны",
            });
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
        this.loading = false;
        return;
      }
    },
    onEditField(e) {
      if (!this.getAccess("site.edit")) return;
      let model = Object.assign({}, e.field);
      model.editField = model.name;
      model.name = "field";
      this.editField = { data: e.row, model: [model], row: e.row };
      this.showEditField = true;
    },
    async onCommitField(e) {
      if (e.name.split(".")[0] == "ads_data") {
        let ads_info = Object.assign({}, this.editField.data.ads_data);
        ads_info[e.name.split(".")[1]] = e.value;
        let id = this.editField.data.ads_id;
        if (!id) return;
        let data = { id, data: ads_info };
        console.log(data);
        let api = "mechti/object_props_value";
        await this.post(data, api);
        this.$refs.table.updateRowData(this.editField.data.id);
      } else {
        let data = { id: this.editField.row.id, [e.name]: e.value };
        let api = this.types[this.objectType].url;
        if (data.id) await this.post(data, api);
        this.$refs.table.updateRowData(data.id);
      }
    },
    onChangeObjectType() {
      if (this.$refs.table) {
        this.$refs.table.refresh();
      }
      return;
      let where = { object_type: { condition: `=`, value: 1 << (this.objectTab - 1) } };
      if (this.objectTab == 2) {
        where["object_type"] = { condition: `&${1 << (this.objectTab - 1)}=`, value: 1 << (this.objectTab - 1) };
      } else if (this.objectTab == 0) {
        where = {};
      }
      //where = Object.assign({}, where, { status: [10, 90] });
    },
    async fitchData() {
      let data;
      let params = {};
      const d = await this.$axios.post("/report_sql", { name: "getObjectsPublished", params });
      if (d.data && d.data.data) {
        data = d.data.data;
      }

      this.data = data;
      this.loading = false;
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idEdit);
      this.loading = !true;
      this.idEdit = 0;
    },
    onSingleClick(d) {},
    onDoubleClick(d) {
      this.onClickRow(d);
    },
    onClickRow(d) {
      this.idEdit = d.row.id;
      this.showEditDialog = true;
      // this.$router.push({ name: "sales_view", params: { id: d.row.id } });
      // this.showEditDialogFun(d.row.id);
    },
  },
};
</script>
